<template>
  <div>
    <h3 class="mb-2">
      Rate plan details for
      <b-badge v-if="ratePlan.RatePlanName" v-once variant="primary">{{
        ratePlan.RatePlanName
      }}</b-badge>
    </h3>
    <b-card title="Options">
      <b-row>
        <b-col cols="12" class="mt-1">
          <b-form
            ref="optionsForm"
            class="repeater-form"
            @submit.prevent="repeatOptionItem"
          >
            <b-row
              v-for="(option, index) in ratePlan.options"
              :id="option.id"
              :key="option.id"
              ref="optionrow"
            >
              <b-col md="7">
                <b-form-group label="Option" label-for="food-name">
                  <b-form-input
                    v-model="ratePlan.options[index].content"
                    type="text"
                    placeholder="Content"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" class="align-self-center text-center">
                <b-img
                  class="mb-1"
                  v-bind="optionProps"
                  v-if="ratePlan.options[index].icon"
                  :src="ratePlan.options[index].icon"
                ></b-img>
                <ComponentMediaSelector
                  :params="{ index }"
                  @mediaSelected="handleOptionMediaSelect"
                  :isMultiple="false"
                />
              </b-col>
              <b-col lg="2" md="2" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeOptionItem(index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatOptionItem"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    ratePlan.options.length === 0 ? "Add Options" : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-card>

    <b-card title="Inclusions">
      <b-row>
        <b-col cols="12" class="mt-1">
          <b-form
            ref="optionsForm"
            class="repeater-form"
            @submit.prevent="repeatInclusionItem"
          >
            <b-row
              v-for="(inclusion, index) in ratePlan.inclusions"
              :id="`inclusion-${index}`"
              :key="`inclusion-${index}`"
              ref="inclusionrow"
            >
              <b-col md="7">
                <b-form-group
                  :label="`Inclusion ${index + 1}`"
                  label-for="food-name"
                >
                  <b-form-input
                    v-model="ratePlan.inclusions[index]"
                    type="text"
                    placeholder="Content"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeInclusionItem(index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatInclusionItem"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    ratePlan.inclusions.length === 0
                      ? "Add Inclusions"
                      : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-button size="lg" @click="updateRatePlan" variant="primary" block
        >Update Rate Plan</b-button
      >
    </b-card>
  </div>
</template>

<script>
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import {
  BForm,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardTitle,
  BButton,
  BRow,
  BCol,
  BFormTextarea,
  BImg,
} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { getUserToken } from "@/auth/utils";
import axios from "axios";

export default {
  components: {
    ComponentMediaSelector,
    BForm,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BCardActions,
    BCardTitle,
    BRow,
    BCol,
    BFormTextarea,
    BImg,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      hotelID: this.$route.params.hotelID,
      RoomId: this.$route.params.roomID,
      ratePlanID: this.$route.params.ratePlanID,
      isDataLoading: true,
      nextOptionsID: 1,
      optionProps: {
        width: 40,
        height: 40,
      },
      ratePlan: {
        RatePlanName: "",
        inclusions: [],
        options: [],
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    getRatePlan() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/hotel/ratePlans/getRatePlans`,
          {
            hotelID: this.hotelID,
            RoomId: this.RoomId,
            RatePlanId: this.ratePlanID,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data[0]);
          const { RatePlanName, options, inclusions } = response.data[0];
          this.ratePlan = {
            RatePlanName,
            options,
            inclusions,
          };
          this.isDataLoading = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.isDataLoading = false;
        });
    },
    updateRatePlan() {
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/hotel/ratePlans/addRatePlanData`, {
          hotelId: this.hotelID,
          RoomId: this.RoomId,
          RatePlanId: this.ratePlanID,
          updates: this.ratePlan,
        },{
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          })
        .then((response) => {
          console.log(response.data);
          this.makeToast(
            "success",
            "Success",
            "Rate Plan updated successfully!"
          );
        })
        .catch((error) => {
          this.makeToast("danger", "Error", "Couldn't update the Rate Plan");
        });
    },
    removeOptionItem(index) {
      this.ratePlan.options.splice(index, 1);
    },
    removeInclusionItem(index) {
      this.ratePlan.inclusions.splice(index, 1);
    },
    repeatOptionItem() {
      this.ratePlan.options.push({
        content: "",
        icon: "",
        id: this.nextOptionsID,
      });
      this.nextOptionsID = this.nextOptionsID + 1;
    },
    repeatInclusionItem() {
      this.ratePlan.inclusions.push("");
    },
    handleOptionMediaSelect(mediaObject) {
      if (mediaObject.status) {
        this.ratePlan.options[mediaObject.params.index].icon =
          mediaObject.media.src;
      }
    },
  },
  created() {
    this.getRatePlan();
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
</style>